import React, { useEffect, useState } from 'react'
import assets from '../assets';
import { logoutFirebase } from '../config/firebase-config';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LuLogOut } from "react-icons/lu";
import { GetLocationDetails } from '../services/api';
const NavBarComp = () => {
    const location =  useLocation();
    const bookingsData = useSelector((store) => store?.bookings);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [locationDetail , setLocationDetail]=useState(null);
      // Function to toggle the dropdown menu
        const toggleDropdown = () => {
            setIsDropdownOpen(!isDropdownOpen);
        };
        let lUrl = localStorage.getItem("user_location");
        let userEmail = localStorage.getItem("user_email");
         
        useEffect(() => {
            const fetchData = async () => {
              try {
                if (!bookingsData.location_id) return;
          
                const locationDetailsResponse = await GetLocationDetails(bookingsData.location_id);
                const locationDetails = locationDetailsResponse?.data?.location_details;
          
                setLocationDetail(locationDetails);
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            };
          
            fetchData();
          }, [bookingsData.location_id]);
          
          
  return (
    <>
       <nav className="flex relative justify-between  py-4 px-20 mmd:px-5 bg-white/80
            backdrop-blur-md shadow-md w-full
             top-0 left-0 right-0 z-10">

            {/* <!-- Logo Container --> */}
            <div className="flex items-center mmd:flex-col gap-2 mmd:gap-0">
                {/* <!-- Logo --> */}
                <Link to="/" className="cursor-pointer">
                    <div className="text-2xl font-medium text-blue-500 w-16">
                        <img
                        className="object-cover"
                        src={assets?.droame_logo_green}
                        alt="Droame Logo in Green"
                        />
                    </div>
                </Link>
                <h1 className='text-center text-[#3F8786] border-b-2 border-black border-double'>{locationDetail?.location_name}</h1>
            </div>
         
         {/* <div className='sm:flex sm:flex-col-reverse sm:justify-around flex gap-5 justify-end  border-2'> */}
            {/* <!-- Links Section --> */}
            <div className="flex items-center space-x-0 xsm:flex-col gap-5 xsm:gap-2 justify-center sm:mr-4">
                <Link to="/" className={`flex text-gray-600
                        cursor-pointer transition-colors duration-300 ${location.pathname.endsWith('/customer-video') ? "text-webGrenn border-b-2 border-webGrenn" : '' }`}>
                        Bookings
                </Link>
                <Link to="/amber-fort/dashboard" className={`flex text-gray-600
                        cursor-pointer transition-colors duration-300 ${location.pathname.endsWith('/dashboard') ? "text-webGrenn border-b-2 border-webGrenn" : '' }`}>
                        Dashboard
                </Link>

              
                {(lUrl === "F9-Go-Karting" ||
                    userEmail === "splashbungee@gmail.com"  ||
                    lUrl === "Kullu-Paragliding" ||
                    lUrl === "Udaipur-Bungee") &&  
                        <Link to={`/${bookingsData?.location_name}/dashboard/`} className={`flex text-gray-600
                                cursor-pointer transition-colors duration-300 ${location.pathname.endsWith('/dashboard/') ? "text-webGrenn border-b-2 border-webGrenn" : '' }`}>
                                Dashboard
                        </Link>}
                     
            </div>
            {/* <!-- Icon Menu Section --> */}
            <div className="flex items-center space-x-5 sm:space-x-0 relative">
                {/* <!-- Register --> */}
                {/* <Link to="/:location/dashboard/" className="flex text-gray  -600 hover:text-blue-500
                    cursor-pointer transition-colors duration-300">
                    Dashboard
                </Link> */}

                {/* <!-- Login --> */}
                <div onClick={()=>{logoutFirebase()}} className="flex text-gray-600 
                    cursor-pointer transition-colors duration-300
                    font-semibold items-center gap-2 justify-center">
                        Logout 
                  <LuLogOut />
                </div>
             
                {/* <div className="relative">
                    <img
                    src="https://res.cloudinary.com/demo/image/twitter/1330457336.jpg" // Replace with actual profile picture URL
                    alt="Profile"
                    className="w-10 h-10 rounded-full cursor-pointer"
                    onClick={toggleDropdown}
                    />
                    {isDropdownOpen && (
                        <div className={`${isDropdownOpen ? "opacity-100" : "opacity-0"} absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 transform transition duration-300 ease-in`}>
                            <ul className="py-1">
                             <li className="px-4 py-2 hover:text-webGrenn hover:border-b-2 hover:border-webGrenn cursor-pointer">Profile</li>
                            <li className="px-4 py-2 hover:text-webGrenn hover:border-b-2 hover:border-webGrenn cursor-pointer">Settings</li>
                            <li className="px-4 py-2 hover:text-webGrenn hover:border-b-2 hover:border-webGrenn cursor-pointer"><button onClick={(e)=>{e.preventDefault() 
                            logoutFirebase()}}>Logout</button></li>
                            </ul>
                        </div>
                    )}
               
                        {isDropdownOpen && (
                        <div
                            className="fixed inset-0 opacity-25 z-10"
                            onClick={() => setIsDropdownOpen(false)}
                        ></div>
                        )}
                </div> */}
            </div>
        {/* </div> */}
        </nav>
    </>
  )
}

export default NavBarComp;