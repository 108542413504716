import React from 'react'
import { S3Client, GetObjectCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";



const AmerComboDashboard = () => {

    const client = new S3Client({ 
      region: "ap-southeast-1", 
      endpoint: "https://s3.ap-southeast-1.wasabisys.com",
      credentials: { 
         accessKeyId: "OHUI9W48PLODXNZR9RTU", 
         secretAccessKey: "65PSzk1zODfhOZlfVVuzi79pUVOAmzgOTXucqefl" 
       }

    });

      

    async function getBucketData(bucketName, objectKey) {
       const command = new ListObjectsV2Command({
           Bucket: bucketName,
          //  Key: "rafting"
       });

      const data = await client.send(command);
      // const fileContent = await data.Body.transformToString();
      return data;

  }
      const fetchObjects = async () => {
        const prefix = 'your/desired/prefix'; 
        const retrievedObjects = await getBucketData("unedited-shots" , "");
        console.log("retrievedObjects : " , retrievedObjects?.Contents);
      };

  return (
    <div onClick={fetchObjects}>AmerComboDashboard</div>
  )
}

export default AmerComboDashboard