import React from 'react'
import { useSelector } from 'react-redux';
import AmerComboDashboard from './AmerComboDashboard';

const LocationDashboard = () => {
    const bookingsData = useSelector((store) => store?.bookings);
    console.log("bookingsData : " ,bookingsData);
  return (<>
            {bookingsData?.location_name === "amber-fort" ? 
                <AmerComboDashboard />
           :  <p>Do not know Location </p>}
         </>

  )
}

export default LocationDashboard