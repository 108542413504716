import React, { useEffect, useState } from 'react'
import { S3Client,ListObjectsV2Command } from "@aws-sdk/client-s3";
import { ToastContainer, toast } from 'react-toastify';
import LogoLoader from '../components/logoloader/LogoLoader';
import axios from 'axios';
import { LuFileAudio } from "react-icons/lu";
import { FaRegFileVideo } from "react-icons/fa"
import { MdFileDownloadDone } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { FaRegFileAudio } from "react-icons/fa";
import { MdOutlineStyle } from "react-icons/md";
import { PiVideoLight } from "react-icons/pi";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdSlowMotionVideo } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetBookings } from '../services/api';

const RiverRaftingPage = () => {
    const [bookings , setBookings]=useState([]);
    const [userSendData , setUserSendData]=useState({});
    const [videoList , setVideoList]=useState([]);
    const [videoList2 , setVideoList2]=useState([]);
    const [loading , setLoading]=useState(false);
    const [disableBtn , setDisableBtn]=useState([]);
    const [filteredData , setFilteredData]=useState([]);
    const [filteredData2 , setFilteredData2]=useState([]);
    const [searchQuery , setSearchQuery]=useState("");
    const [dataMethod ,setDataMethod]=useState("bookings");
    const [isConnected, setIsConnected] = useState(false);
    // const [transport, setTransport] = useState("N/A");
    const [socketMsg , setSocketMsg]=useState(undefined);
    const [editLoader , setEditLoader]=useState([]);
    const editingStatus = [{key:"files_downloaded" , keyVal:"pending"} , {key:"add_raft_audio_to_videos", keyVal:"pending"},{key:"added_vfx_to_videos",keyVal:"pending"} , {key:"added_bgm_to_videos",keyVal:"pending"} , {key:"uploading_to_bucket",keyVal:"pending"}, {key:"link_ready", keyVal:"pending"}];

    const client = new S3Client({ 
        region: "ap-southeast-1", 
        endpoint: "https://s3.ap-southeast-1.wasabisys.com",
        credentials: { 
           accessKeyId: "OHUI9W48PLODXNZR9RTU", 
           secretAccessKey: "65PSzk1zODfhOZlfVVuzi79pUVOAmzgOTXucqefl" 
         }
  
      });

    async function getBucketData(bucketName, prefix , idx) {
        const command = new ListObjectsV2Command({
            Bucket: bucketName,
            MaxKeys: 500000, 
            Prefix : prefix,
        });
 
       const data = await client.send(command);
       // const fileContent = await data.Body.transformToString();
       return data;
    }
    const fetchBucketData = async (bId , userSelectDate , idx) => {
    setLoading(true);
    if(bId && userSelectDate && idx){
        const preFix = `${bId}/${userSelectDate}/`
        console.log(`prefix is ${preFix}`);
        const bookingsData = await getAllBookings(); 
        const shotsData = await getShotsQueue();
        try {
         const retrievedObjects = await getBucketData("unedited-shots" , preFix , idx);
         console.log("retrievedObjects : " , retrievedObjects?.Contents);
         const { results, shotsResults } = processContents(
            retrievedObjects?.Contents,
            bookingsData,
            shotsData,
            userSelectDate
          );
          console.log("bookings-dash======> :", results);
          console.log("shots-dash======> :", shotsResults);
          setVideoList(results);
          setFilteredData(results);
          setVideoList2(shotsResults);
          setFilteredData2(shotsResults);

        }catch(error){
            toast.error(error ? error :"Something went wrong.", {
                position: "top-center",
                 autoClose: 10000 
              });
        }finally{
            setLoading(false);
        }
     }else{
        console.log("not found date");
     }  
    };
     
    useEffect(()=>{
      getData();
    },[])

    useEffect(() => {
        if(userSendData?.bid && userSendData?.selectDate) {
            fetchBucketData(userSendData?.bid , userSendData?.selectDate , 2);
        };
      }, [userSendData]);
    

      //* Add Socket 


      const extractNumber = (inputString) => {
        // Regular expression to capture the number after the last underscore and before the .mp4 extension
        const regex = /_(\d+)\.mp4$/;
        const match = inputString.match(regex);
        
        // If a match is found, return the captured number
        if (match) {
          return match[1]; // match[1] contains the captured number
        }
        
        // Return null if no match is found
        return null;
      };
    
      //* Process and Change Data
      const processContents = (contents, bookingsData, shotsData, userSelectDate) => {
        const results = [];
        const shotsResults = [];
        
        if (!contents) return { results, shotsResults };
      
        contents.forEach(object => {
          const parts = object.Key.split('/');
          if (parts.length <= 3) return;
      
          const uniqueIdentifier = parts[2];
          const fileName = parts[3];
          
          // Get booking and shots data
          const bookingsObj = bookingsData?.find(data => 
            `${data.customer_name}_${data.phone_number}` === uniqueIdentifier
          ) || {};
      
          const shotsObj = shotsData?.find(data => 
            `${data.customer_name}_${data.customer_phone_number}` === uniqueIdentifier && 
            getFormateDate(data.created_at) === userSelectDate
          ) || {};
      
          // Process file information
          const epochTime = extractNumber(fileName);
          const formattedTime = epochTime ? 
            new Date(epochTime * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 
            "0";
      
          const fileNumber = fileName.match(/^\d+/)?.[0];
          const isAudioFile = fileName?.startsWith("audio");
          const arrayIndex = isAudioFile ? 12 : parseInt(fileNumber) - 1;
          
          const resultKey = parts.slice(0, -1).join('/') + '/';
      
          // Function to create a new base entry
          const createBaseEntry = () => ({
            keyName: resultKey,
            idName: uniqueIdentifier,
            arr: new Array(13).fill(null).map(() => ({
              vidTime: "00:00",
              vid: false,
              count: 0
            }))
          });
      
          // Update results array
          let entry = results.find(item => item.idName === uniqueIdentifier);
          if (!entry) {
            entry = { ...createBaseEntry(), ...bookingsObj };
            results.push(entry);
          }
      
          // Update shotsResults array (only if we have a valid shotsObj)
          let entry2 = shotsResults.find(item => item.idName === uniqueIdentifier);
          if (!entry2 && shotsObj?.booking_id) {
            entry2 = { ...createBaseEntry(), ...shotsObj };
            shotsResults.push(entry2);
          }
      
          // Update video/audio status
          if (arrayIndex >= 0 && arrayIndex < 13) {
            // Update entry1
            if (entry) {
              const currentObj = entry.arr[arrayIndex];
              entry.arr[arrayIndex] = {
                vid: true,
                vidTime: formattedTime,
                count: (currentObj?.count || 0) + 1
              };
            }
      
            // Update entry2
            if (entry2) {
              const currentObj2 = entry2.arr[arrayIndex];
              entry2.arr[arrayIndex] = {
                vid: true,
                vidTime: formattedTime,
                count: (currentObj2?.count || 0) + 1
              };
            }
          }
        });
      
        return { results, shotsResults };
      };
    
    
      const getData=async(bId , userSelectDate , idx)=>{
        setLoading(true);
        if(bId && userSelectDate && idx){
          const preFix = `${bId}/${userSelectDate}/`
          console.log(`prefix is ${preFix}`);
          const bookingsData = await getBookings(); 
          const shotsData = await getShotsQueue();
          try {
            const response = await fetch('/api/getRiverRaftingData', {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({prefix:preFix, findIndx:idx}) 
            });
            if (!response.ok) {
              // Handle specific status codes as needed
              if (response.status === 404) {
                toast.info("Something went wrong. Kindly coose correct date !", {
                  position: "top-center",
                   autoClose: 10000 
                });
                throw new Error('Internal Server Error: Please try again later.');
              }
              // You can handle other status codes as well
              throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            const { results, shotsResults } = processContents(
              data?.data?.Contents,
              bookingsData,
              shotsData,
              userSelectDate
            );
    
             console.log("bookings-dash======> :", results);
             console.log("shots-dash======> :", shotsResults);
             setVideoList(results);
             setFilteredData(results);
             setVideoList2(shotsResults);
             setFilteredData2(shotsResults);
             
          } catch (error) {
            toast.error(error ? error :"Something went wrong.", {
              position: "top-center",
               autoClose: 10000 
            });
          }finally{
            setLoading(false);
          }
    
        }else{
          try {
            const response = await getBucketData("unedited-shots" , "")
            console.log("data-api :", response?.Contents);
            console.log("keys :", response?.keys);
            const uniqueKeys = new Set();
    
          response?.Contents.forEach((object) => {
                // Split the key by '/' and take the first part
                const parts = object.Key.split('/');
                // console.log("parts : " , parts);
                if (parts.length > 0) {
                  if(parts[0]){
                    uniqueKeys.add(parts[0]); // Add the first part (unique identifier)
                  }
                }
            });
          // Convert the Set back to an array
            const keysArray = Array.from(uniqueKeys);
            console.log("keysArray : " , keysArray);
            setBookings(keysArray);
          } catch (error) {
            toast.error(error ? error :"Something went wrong.", {
              position: "top-center",
               autoClose: 10000 
            });
          }finally{
            setLoading(false);
          }
        }
      
      };
    
      const formateDate=(dateData)=>{
        const data = new Date(dateData);
         // Get the year, month, and day
          const year = data.getFullYear();
          const month = String(data.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
          const day = String(data.getDate()).padStart(2, '0');
          // Format to YYYY-MM-DD
          const formattedDate = `${year}-${month}-${day}`;
          setUserSendData((prev)=>({...prev , selectDate:formattedDate}));
     };
    
     const getFormateDate=(dateData)=>{
      const data = new Date(dateData);
       // Get the year, month, and day
        const year = data.getFullYear();
        const month = String(data.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(data.getDate()).padStart(2, '0');
        // Format to YYYY-MM-DD
        return `${year}-${month}-${day}`;
    };
      
      //* Edit Trigger Api Call
      const triggerEdit=async(dataObj , idx)=>{
        console.log("dataObj : " , dataObj);
        if(dataObj?.video_uri_wasabi || dataObj?.video_uri){
          return ;
        }
        if(dataObj?.booking_id && dataObj?.customer_id){
          setEditLoader((prev)=>[...prev , idx]);
          try {
            const response = await axios.post(`https://editing.droame.com/rafting`, {
              // resource: '/rafting',
              editLink:dataObj?.keyName,
              booking_id:dataObj?.booking_id,
              customer_id:dataObj?.customer_id} , {  
                timeout: 720000,
                headers: {"X-TOKEN": "eijnfcmhhdtbcnoscizsgmlyujqjklpa"}  
              });
             
              const resData = JSON.parse(response.data.body);
              console.log("response ; " , resData);
              console.log("edit-response : " , response);
              setEditLoader((prev)=>prev.filter(item => item !== idx));
           } catch (error) {
            setEditLoader((prev)=>prev.filter(item => item !== idx));
              toast.info( "The edit button is currently disabled. Please wait 5 minutes before trying again..",{
                position: "top-center",
                 autoClose: 10000 
              });
             
           }
        }else{
          toast.info( "The booking data is currently unavailable, so the video cannot be edited.",{
            position: "top-center",
             autoClose: 10000 
          });
        }
        
      }
      
      const getBookings=async()=>{
       
        try {
          let response = await axios.get(`https://92wfuazjfg.execute-api.us-east-1.amazonaws.com/dev/bookings`, {
            params: {
              location_id: 'ae8f69ae-39d8-442a-b2ab-11a36b93e470',
              created_at: `${userSendData?.selectDate}`,
            },
          });
          
          // console.log("bookings Response ; " , response?.data?.booking);
          const resultArr = response?.data?.booking
          return response?.data?.booking;
        } catch (error) {
          console.log("bookings-error ; " , error);
        }
      };

      const getAllBookings=async()=>{
        const res = await GetBookings("ae8f69ae-39d8-442a-b2ab-11a36b93e470" , userSendData?.selectDate);
        if(res?.data?.is_success){
         return res?.data?.bookings;
        }
        return [];
      }
    
      const getShotsQueue=async()=>{
      try {
        let response = await axios.get(`https://w3xoty7ng0.execute-api.us-east-1.amazonaws.com/dev/locations/${"ae8f69ae-39d8-442a-b2ab-11a36b93e470"}/sub_location/${"3e1a448e-6878-42d9-beb8-ae83e1b5a267"}/shots_queue`, {
        });
        
        // console.log("shots Response ; " , response?.data?.shots);
        return response?.data?.shots;
      } catch (error) {
        console.log("bookings-error ; " , error);
      }
      }
      
      const handleFilterdData=(e)=>{
         const query = e.target.value.toLowerCase();
         setSearchQuery(query);
         if(!query || query === ""){
          dataMethod === "bookings" ? setFilteredData(videoList) : setFilteredData2(videoList) ;
         }else{
           if(dataMethod === "bookings"){
            const filtered = videoList?.filter(item=>item.idName.toLowerCase().includes(searchQuery));
            setFilteredData(filtered);
           }else{
            const filtered2 = videoList2?.filter(item=>item.idName.toLowerCase().includes(searchQuery));
            setFilteredData2(filtered2);
           }
         
         }
      }
     
      const addVideoId=async()=>{
        const bookingsData = await getBookings();
        bookingsData?.forEach((bdata)=>{
           videoList?.forEach((vidObj)=>{
            if(vidObj?.booking_id === bdata?.booking_id){
                const dataObj = {...vidObj};
                vidObj = {...dataObj ,videoUri:bdata?.videoUri}
            }
           })
        })
        setVideoList(); 
        setFilteredData();
      }

      const giveIcon = (data)=>{
          switch (data) {
            case "files_downloaded":
              return <FaRegFileVideo className="text-lg text-blue-500"/>
    
            case "add_raft_audio_to_videos":
              return <FaRegFileAudio className="text-lg text-blue-500" />
    
            case "added_vfx_to_videos":
                return <MdOutlineStyle className="text-xl text-blue-500" />
                
            case "added_bgm_to_videos":
                  return <PiVideoLight className="text-xl text-blue-500" />
    
            case "uploading_to_bucket":
              return <AiOutlineCloudUpload className="text-xl text-blue-500" />
    
            case "link_ready":
              return <MdSlowMotionVideo className="text-xl text-blue-500" />
          
            default:
              break;
          }
      };
    
      const SmallLoader = () => {
        return (
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        );
      };
    
      const dataSelect = [{label:"Bookings" ,value:"bookings"} ,{label:"Shots" , value:"shots"}];
    
      const deleteShots=async(data)=>{
        setLoading(true);
        try {
          let response = await axios.patch(`https://92wfuazjfg.execute-api.us-east-1.amazonaws.com/dev/customer_details/${data?.customer_id}/bookings/${data?.booking_id}`, {
            action:"shot_deleted",
            video_timestamp:"0",
            booking_id:data?.booking_id,
            location_id:"ae8f69ae-39d8-442a-b2ab-11a36b93e470",
            sub_location_id:"3e1a448e-6878-42d9-beb8-ae83e1b5a267",
            current_status:"deleted"
          });
          
          console.log("shots Response ; " , response);
            if(response?.data?.is_success){
              toast.success(response?.data?.message, {
                position: "top-center",
                autoClose: 10000 
              });
              getData();
            }else{
              toast.error(response?.data?.message, {
                position: "top-center",
                autoClose: 10000 
              });
            }
        } catch (error) {
          console.log("bookings-error-deleted ; " , error);
            toast.error("Something Went Wrong", {
              position: "top-center",
              autoClose: 10000 
            });
        }finally{
          setLoading(false);
        }
      };

      const demofun=()=>{
        const jsonData = {
          message: 'files downloaded',
          booking_id: "aebd2259-f42f-4350-8537-1a1ff853290c",
          customer_id: "aebd2259-f42f-4350-8537-1a1ff853290c",
          keyName: 'files_downloaded',
          keyStatus : "done",
        };
    
        // handleSocketMessages(jsonData);
      }

   
      console.log("usersend data ========= >: " , userSendData);
    return (
        <div className="h-screen max-w-screen w-screen flex flex-col items-center overflow-hidden">

        <h1 onClick={getAllBookings} className="text-center font-bold my-4">River Rafting Dashboard</h1>
       
      <div className="w-[95%] border-21">
        <div className="w-full flex justify-between">
          {/* <Dropdown title={userSendData?.bid ? userSendData?.bid : "Bookings"}>
            {bookings?.map((val,idx)=>(
                <Dropdown.Item key={idx} onClick={()=>setUserSendData((prev)=>({...prev , bid:val}))}>{val}</Dropdown.Item>
            ))}
          </Dropdown>
  
          <Dropdown title={dataMethod}>
            {dataSelect?.map((val,idx)=>(
                <Dropdown.Item key={idx} onClick={()=>setDataMethod(val?.value)}>{val?.label}</Dropdown.Item>
            ))}
          </Dropdown> */}
         
         <select
              id="locations"
               onChange={(e)=>setUserSendData((prev)=>({...prev , bid:e.target.value}))}
               className="custom-select sources rounded-full border focus:ring-0 border-gray-200 bg-white"
                placeholder={userSendData?.bid ? userSendData?.bid : "Bookings"}
                // disabled={subLocationList?.length < 1 ?  true : false}
                value={userSendData?.bid}
                >
             <option hidden value="default" >Location</option>
             {bookings?.map((val,idx) => (
                  <option key={idx} value={val}> {val} </option>
             ))}
             </select>

         <select
              id="locations"
               onChange={(e)=>setDataMethod(e.target.value)}
               className="custom-select sources rounded-full border focus:ring-0 border-gray-200 bg-white"
                placeholder={dataMethod}
                // disabled={subLocationList?.length < 1 ?  true : false}
                value={dataMethod}
                >
             <option hidden value="default" >Location</option>
             {dataSelect?.map((val,idx) => (
                  <option key={idx} value={val?.value}> {val?.label} </option>
             ))}
             </select>

          {/* <ClientDatePicker oneTap block placeholder="Select Date" format="dd/MM/yyyy" style={{ width:280}} onChange={formateDate}/> */}
          <DatePicker
                                    // selected={new Date(userSendData?.selectDate)}
                                    className="w-[70%] rounded-md border-none ring-0"
                                    // onChange={(date) => {
                                    //     const year = date.getFullYear();
                                    //     const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1 to get the actual month number
                                    //     const day = date.getDate();

                                    //     const formattedDate = `${year}-${month
                                    //         .toString()
                                    //         .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
                                    //         setDate(formattedDate);
                                    //         // dispatch(setCurrentDate(formattedDate));
                                    //     }}
                                    onChange={formateDate}
                                    dateFormat="dd-MM-yyyy"
                                    isClearable={false}
                                    minDate={new Date(new Date().setDate(new Date().getDate() - 60))}
                                    placeholderText={userSendData?.selectDate ? userSendData?.selectDate : "Select Date"}
                                    maxDate={new Date()}
                                />
          </div>
        
         
        <div className="mx-auto mt-8 ">
          <div className="sm:flex sm:items-center sm:justify-between flex-col sm:flex-row">
  
          <form className="relative flex w-full max-w-2xl items-center">
            <svg className="absolute left-2 block h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="11" cy="11" r="8" className=""></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65" className=""></line>
            </svg>
            <input type="name" name="search" className="h-12 w-full border-b-gray-400 bg-transparent py-4 pl-12 text-sm outline-none focus:border-b-2" placeholder="Search by Customer" value={searchQuery} onChange={handleFilterdData}/>
          </form>
  
            <div className="mt-4 sm:mt-0">
              <div className="flex items-center justify-start sm:justify-end">
                <div className="flex items-center">
                  <label htmlFor="" className="mr-2 flex-shrink-0 text-sm font-medium text-gray-900"> Bookings: </label>
                  {/* <select name="" className="sm: mr-4 block w-full whitespace-pre rounded-lg border p-1 pr-10 text-base outline-none focus:shadow sm:text-sm">
                    <option className="whitespace-no-wrap text-sm">Recent</option>
                  </select> */}
                </div>
  
                <div className="inline-flex cursor-pointer items-center rounded-lg border border-gray-400 bg-white py-2 px-3 text-center text-sm font-medium text-gray-800 shadow hover:bg-gray-100 focus:shadow">
                    {filteredData?.length}
                </div>
              </div>
            </div>
          </div>
  
          <div className="mt-6 overflow-auto rounded-xl border shadow max-h-[76vh]">
            {loading ? <LogoLoader/> :
            <table className="min-w-full border-separate border-spacing-y-2 border-spacing-x-2 overflow-auto table-auto ">
              <thead className=" border-b table-header-group">
                <tr className="">
                  <td  className="whitespace-normal py-4 text-sm font-medium text-gray-500 px-auto">Customer</td>
                  <td  className="whitespace-normal py-4 text-sm font-medium text-gray-500 px-auto">Number</td>
                  {dataMethod === "shots" && <td width="20%" className="whitespace-normal py-4 text-sm font-medium text-gray-500 sm:px-6">Raft Compony</td>}
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">1</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">2</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">3</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">4</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">5</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">6</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">7</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">8</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">9</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">10</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">11</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center">12</td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500  text-center"><LuFileAudio className="text-xl mx-auto"/></td>
                  <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 ">Edit Video</td>
                {dataMethod === "shots" && <td className="whitespace-normal py-4 text-sm font-medium text-gray-500 ">Delete Video</td>}
                </tr>
              </thead>
  
              <tbody className="lg:border-gray-300 ">
                {dataMethod === "bookings" &&  filteredData ?.map((val,i)=>(
                  <React.Fragment key={`booking-${val.idName}-${i}`}>
                  <tr key={i}>
                      <td  className={`whitespace-no-wrap pt-4 text-sm font-bold ${val?.booking_id ? "text-gray-900" :"text-red-900"} `}>
                    {val?.booking_id ? val?.customer_name : val?.idName}</td>
                    <td  className={`whitespace-no-wrap py-4 text-sm font-bold ${val?.booking_id ? "text-gray-900" :"text-red-900"} `}>
                    {val?.booking_id ? val?.phone_number : "Not Found"}</td>
  
                    {val?.arr?.map((arrVal,index)=>
                        <td key={index+1} className="whitespace-no-wrap pt-4 text-sm font-normal text-gray-500  table-cell">
                          <div className="flex flex-col justify-center items-center gap-2">
                            <div className={`w-5 h-5 rounded-full text-center ${arrVal?.vid ? "bg-green-500" : "bg-red-500"}`}><span className="text-white font-bold">{arrVal?.count > 1 ? arrVal?.count : ''}</span></div>
                            <p className="font-medium">{arrVal?.vidTime}</p>
                          </div>
                          
                        </td>
                    )}
  
                    
                    <td className="whitespace-no-wrap pt-4 text-sm font-normal text-gray-500 px-auto table-cell cursor-pointer">
                      {editLoader?.includes(i)? <div className="inline-flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600 cursor-default">{SmallLoader()}</div>:
                        (val?.video_uri_wasabi || val?.video_uri) ? <a href={val?.video_uri_wasabi ? val?.video_uri_wasabi:val?.video_uri} target="_blank" className="inline-flex items-center px-6 py-3 text-blue-500 bg-blue-100 rounded-md hover:bg-blue-200 hover:text-blue-600 font-bold">View</a> :
                      <div onClick={()=>triggerEdit(val , i)} className="inline-flex items-center px-6 py-3 text-green-500 bg-green-100 rounded-md hover:bg-green-200 hover:text-green-600 font-bold">Edit</div>}
                    </td>
                  
  
                  </tr>
                  {val?.editingStatus && <tr className="mt-0">
                     <td colSpan="16" className="m-0">
                     <div className="flex justify-around w-ful m-0">
                        {val?.editingStatus?.map((val,indexNum)=>(
                          <div key={`87${indexNum+1}09`} className="flex gap-2 items-center justify-center">
                             {giveIcon(val?.key)}
                             <div>{val?.keyVal==="done" ? <MdFileDownloadDone className="text-lg text-green-500"/> : val?.keyVal === "pending" ? <SmallLoader/> : <VscError className="text-lg text-red-500"/>}</div>
                          </div>
                          // <p key={`wha${index+1}`} className="bg-green-300">{val?.key} = {val?.keyVal ? "true" : "false"}</p>
                        ))}
                         </div>
                        {/* <p>what isddd d fd ddkfjds skfsdjfkdsjf dskfds fjds kfjdskjfdskjfdskjfdskjfdksjfdksj fkdsf dsfkdsfksdjfkdsj</p> */}
                     </td>
                  </tr>}
                  </React.Fragment>
                 ))}
  
              {dataMethod === "shots" && filteredData2?.map((val,idx)=>(
                <tr className="" key={idx}>
  
                  <td  className={`whitespace-no-wrap py-4 text-sm font-bold ${val?.booking_id ? "text-gray-900" :"text-red-900"}`}>
                   {val?.customer_name}</td>
                   <td  className={`whitespace-no-wrap py-4 text-sm font-bold ${val?.booking_id ? "text-gray-900" :"text-red-900"}`}>
                   {val?.phone_number}</td>
                    <td width="20%" className={`whitespace-no-wrap py-4 text-sm font-bold ${val?.booking_id ? "text-gray-900" :"text-red-900"} sm:px-6`}>
                   {val?.identifier?.raft_company} - ({val?.identifier?.raft_ut_no})</td>
  
                  {val?.arr?.map((arrVal,index)=>
                      <td key={index+1} className="whitespace-no-wrap py-4 text-sm font-normal text-gray-500 px-auto table-cell">
                      <div className="flex flex-col justify-center items-center gap-2">
                        <div className={`w-5 h-5 rounded-full text-center ${arrVal?.vid ? "bg-green-500" : "bg-red-500"}`}><span className="text-white font-bold">{arrVal?.count > 1 ? arrVal?.count : ''}</span></div>
                        <p className="px-auto font-medium">{arrVal?.vidTime}</p>
                      </div>
                    </td>
                  )}
  
                   
                  <td className="whitespace-no-wrap py-4 text-sm font-normal text-gray-500 px-auto table-cell cursor-pointer">
                     {disableBtn?.includes(idx)? <div className="inline-flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600 cursor-default">Edit</div>:  
                      (val?.video_uri_wasabi || val?.video_uri) ? <a href={val?.video_uri_wasabi ? val?.video_uri_wasabi:val?.video_uri} target="_blank" className="inline-flex items-center px-6 py-3 text-blue-500 bg-blue-100 rounded-md hover:bg-blue-200 hover:text-blue-600 font-bold">View</a> :
                     <div onClick={()=>triggerEdit(val , idx)} className="inline-flex items-center px-6 py-3 text-green-500 bg-green-100 rounded-md hover:bg-green-200 hover:text-green-600 font-bold">Edit</div>}
                  </td>
                  <td className="whitespace-no-wrap py-4 text-sm font-normal text-gray-500 px-auto table-cell cursor-pointer">
                     {/* {disableBtn?.includes(idx)? <div className="inline-flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600 cursor-default">Edit</div>: */}
                     <div onClick={()=>deleteShots(val)} className="inline-flex items-center px-6 py-3 text-red-500 bg-red-100 rounded-md hover:bg-red-200 hover:text-delete-600 font-bold">Delete</div> 
                     {/* } */}
                  </td>
                  {/* <td className="whitespace-no-wrap py-4 px-6 text-right text-sm text-gray-600 lg:text-left">
                    $29.00
                    <div className="flex mt-1 ml-auto w-fit items-center rounded-full bg-blue-600 py-2 px-3 text-left text-xs font-medium text-white lg:hidden">Complete</div>
                  </td>
  
                  <td className="whitespace-no-wrap hidden py-4 text-sm font-normal text-gray-500 sm:px-6 lg:table-cell">
                    <div className="inline-flex items-center rounded-full bg-blue-600 py-2 px-3 text-xs text-white">Complete</div>
                  </td> */}
  
                </tr>
                 ))}
  
             </tbody>
  
            </table>
            }
          </div>
        </div>
  
        </div>
      </div>
    )
    }

export default RiverRaftingPage