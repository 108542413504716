import React, { useEffect, useState } from 'react'
import assets from '../assets'
import { toast } from 'react-toastify';
import { logInWithEmailAndPassword, logoutFirebase } from '../config/firebase-config';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setLocationId, setLocationName } from '../actions/BookingAction';


const LoginPage = () => {
    const [formData ,setFormData]=useState({email:'' , password:'' , isChecked:false});
    const [loginLoading ,setLoginLoading]=useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bookingsData = useSelector((store) => store?.bookings);
    const userToken = localStorage.getItem('user_token');
    const locationName = localStorage.getItem('user_location');
    useEffect(()=>{
        // console.log("loign ")
        if (userToken && userToken !== "") {
             navigate(`/${locationName}/customer-video`);
       }
    },[])
    
    var mailLocationsMap = {
        "thrillfactorys@gmail.com": "the-thrill-factory",
        "retargeting_thrill@droame.com":"the-thrill-factory",
        "thrill_factory_videos@gmail.com": "the-thrill-factory",
        "akshit7425@gmail.com": "the-thrill-factory",
        "thepinkfalcon@gmail.com": "the-pink-falcon",
        "f9Gokarting@gmail.com": "F9-Go-Karting",
        "splashbungee@gmail.com": "Splash-Bungee",
        "kulluparagliding@gmail.com": "Kullu-Paragliding",
        "droameoffice@gmail.com": "Droame-Office",
        "themanalibungee@droame.com":"the-manali-bungee",
        "amerfort@droame.com":"amber-fort",
         "raft@droame.com":"River-Rafting"
      };

      var locationIdMap = {
        "thrillfactorys@gmail.com": "ad49e503-d48d-448b-af97-00d0365e2a28",
        "thrill_factory_videos@gmail.com": "ad49e503-d48d-448b-af97-00d0365e2a28",
        "retargeting_thrill@droame.com":"ad49e503-d48d-448b-af97-00d0365e2a28",
        "akshit7425@gmail.com": "ad49e503-d48d-448b-af97-00d0365e2a28",
        "thepinkfalcon@gmail.com": "the-pink-falcon",
        "f9gokarting@gmail.com": "407fadef-f0af-46bd-ae1e-4d148e32b9a1",
        "splashbungee@gmail.com": "430021b2-8468-4e76-9dc7-88a9231d6df3",
        "kulluparagliding@gmail.com": "a614095a-4b85-4482-8d33-191e30dcf42e",
        "udaipurbungee@gmail.com": "e7f98a91-5ba0-48ca-81a7-a9c9d7ae96c2",
        "droameoffice@gmail.com": "9e0f4948-1533-4723-b0ee-325a5e7c7812",
        "themanalibungee@droame.com":"16a3f9c1-c73e-4814-a38e-971ab10ae113",
        "amerfort@droame.com":"f347809d-5700-5bec-fc1f-3b179ba42dd2",
         "raft@droame.com":"ae8f69ae-39d8-442a-b2ab-11a36b93e470"
      };

    const handleFormData=(e)=>{
        let name = e.target.name;
        if(name === "isChecked"){
            setFormData((prev)=>({...prev , [name]:e.target.checked}))
        }else{
            setFormData((prev)=>({...prev , [name]:e.target.value}))
        }   
    };

    const handleSubmitLoginForm=(e)=>{
        if (!formData?.email?.trim() || !formData?.password?.trim() || !formData?.isChecked) {
            toast.warning("Please fill in all fields");
            return ;
          };
          setLoginLoading(true);
          logInWithEmailAndPassword(formData?.email, formData?.password)
          .then((res) => {
            const location = mailLocationsMap[res?.user?.email];
            localStorage.setItem("user_token", res?.user?.accessToken);
            localStorage.setItem("user_email", res?.user?.email);
            localStorage?.setItem("user_location" , location);
            dispatch(setLocationId(locationIdMap[res?.user?.email]));
            dispatch(setLocationName(location));
            setLoginLoading(false);
            navigate(`/${location}/customer-video`);

          })
          .catch((err) => {
            toast.warning(err?.message);
            setLoginLoading(false);
          });
    }
    
  
  return (
   <>
     <div className="max-h-screen h-screen  bg-gray-100 text-gray-900 flex justify-center">
   
        <div className="max-w-screen-xl m-0 xsm:m-5 bg-white shadow sm:rounded-lg flex justify-center flex-1  ">
  
                    
           
            <div className="flex-1 p-6 xsm:p-6 mmd:w-full">
                <div className='w-32'>
                    <img src={assets?.droame_logo_green}
                        className="w-full h-full" alt='img' />
                </div>
                <div className="mt-0 flex flex-col items-center">
                    <div className="w-full flex-1 mt-8">
                       <div className="my-12 xsm:my-6 border-b text-center">
                            <div
                                className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                                 Sign In with Droame E-mail
                            </div>
                        </div>

                        <div className="mx-auto max-w-xs">
                            <input
                                className="w-full xsm:px-8 px-8 py-4 rounded-lg focus:ring-0 font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                                type="email" id='email' name='email' placeholder="Email"  onChange={handleFormData} value={formData?.email}/>
                            <input
                                className="focus:ring-0 w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                                type="password" id='password' name='password' placeholder="Password" onChange={handleFormData} value={formData?.password}/>

                            <div className="my-4 flex gap-5 items-center justify-center text-xs text-gray-600">
                                                    <input
                                                    type="checkbox"
                                                    id="agree"
                                                    name='isChecked'
                                                    checked={formData?.isChecked}
                                                    onChange={handleFormData}
                                                    />
                                                    <label htmlFor="agree">
                                                    I agree to the{" "}
                                                    <a
                                                        href="https://www.droame.com/TermsAndConditions/"
                                                        target="_blank"
                                                        className="border-b border-gray-500 border-dotted"
                                                        rel="noreferrer"
                                                    >
                                                        Terms & Conditions
                                                    </a>{" "}
                                                    &{" "}
                                                    <a
                                                        href="https://www.droame.com/PrivacyPolicy/"
                                                        className="border-b border-gray-500 border-dotted"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                    </label>
                             </div>


                            <button 
                                onClick={handleSubmitLoginForm}
                                className="mt-5 tracking-wide text-white font-semibold bg-darkGrenn text-white-500 w-full py-4 rounded-lg hover:bg-green-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                                <svg className="w-6 h-6 -ml-2" fill="none" stroke="currentColor" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                    <circle cx="8.5" cy="7" r="4" />
                                    <path d="M20 8v6M23 11h-6" />
                                </svg>
                                <span className="ml-">
                                     Sign In
                                </span>
                            </button>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 bg-darkGrenn  text-center flex  mmd:hidden">
                <div className="m-0 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
                    style={{backgroundImage:`url(${assets?.localized_autonomy_drone})`}}>
                </div>
            </div>
        </div>
    </div>


    
   </>
  )
}

export default LoginPage